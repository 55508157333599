import React from "react"
import CourseDetail from "../../components/CourseDetail"
import { getCourseDetailFor } from "../../shared/constants"
import Layout from "../../components/layout"
import { OTMaterialUiReview } from "../../components/CourseDetail/OTMaterialUiReview"
import { seoData } from "../../shared/seoData"
import SEO from "../../components/SEO"

export default () => {
  const course = getCourseDetailFor("material-ui-with-react");
  const handleBuyCourseClick = () => window.open(course.buyUrl, "_self")
  let seo = seoData.materialUiWithReact;

  return (
    <>
      <SEO title={seo.title} description={seo.metaDescription} pathname={seo.pathname}/>
      <Layout selectedTabValue={false} hideNews>
        <CourseDetail
          onBuyCourseClick={handleBuyCourseClick}
          course={course}
          render={() => <OTMaterialUiReview />}
        />
      </Layout>
    </>
  )
}
