import { Grid, makeStyles, Typography, Avatar } from "@material-ui/core"
import photo from "../../imgs/testimonials/olivier.jpeg"
import React from "react"

const otReviewText = '"bonsaiilabs\'s course on Material-UI is a great way to get started ✨. Learn all the key concepts and how to apply them in practice."'
const useStyles = makeStyles(theme => ({
  reviewContainer: {
    backgroundColor: theme.palette.secondary[800],
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  photo: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(2)
    }
  },
}))

export const OTMaterialUiReview = () => {
  const classes = useStyles()
  return (
    <Grid
      container
      direction="column"
      justify={"center"}
      alignItems={"center"}
      className={classes.reviewContainer}
    >
      <OtReview classes={classes} className={classes}/>
    </Grid>
  )
}

const OtReview = ({ classes }) => {
  return (
    <Grid
      container
      alignItems={"center"}
      justify={"center"}
    >
      <Grid item xs={11} md={2} lg={1}>
        <Avatar alt="Olivier Tassinari" src={photo} className={classes.photo}/>
      </Grid>
      <Grid item xs={11} md={8}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant={"subtitle1"} color={"secondary"} paragraph>{otReviewText}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={"body2"} color={"secondary"}><i>- Olivier Tassinari, Co-founder at
              Material-UI</i></Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}